export default {
    data() {
        return {
            count: 0,
            search: '',
            searchTimeout: -1,
            isLoadingBrandList: true,
            brands: [],
            activeBrand: { id: -1, description: "Brands are Loading" }
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.count / 10);
        },
    },
    watch: {
        search(data) {
            if(this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.GET_INITIAL_BRAND_LIST(1, 10, data)
            }, 1000);
    },
    },
    methods: {
        async GET_INITIAL_BRAND_LIST(page = 1, limit = 10, search = '') {
            this.isLoadingBrandList = true;
            const response = await this.$api.get(
                `Brands?offset=${page}&limit=${limit}&searchKey=${search}`
            );
            if (response.result === "OK" && response.message === "OK") {
                this.brands = response.data.items;
                this.count = response.data.count;
                if (this.$route.params.brandId) {
                    this.activeBrand = response.data.find(d => d.id === Number(this.$route.params.brandId))
                }
            }
            else this.brands = [];
            this.isLoadingBrandList = false;
        },
    },
    mounted() {
        this.GET_INITIAL_BRAND_LIST();
    }
}