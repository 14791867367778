<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <button class="btn btn-primary d-flex" @click="showNewBrandModal = true">
        <i class="material-icons-outlined">add</i>
        <span class="ml-2"> New Brand</span>
      </button>
    </div>
    <div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="">Search Brands</label>
          <div class="form-group position-relative">
            <input
              class="form-control"
              v-model="search"
              required
              type="text"
              aria-describedby="emailHelp"
              placeholder="Enter Brands"
            />
            <i
              v-if="search !== ''"
              @click="search = ''"
              title="Clear of items"
              class="ml-2 material-icons-outlined text-primary close-input"
              >close</i
            >
          </div>
        </div>
      </div>
      <card-table-header :headers="fields"></card-table-header>
      <cardTableBody
        :allItemsCount="brands.length"
        :items="brands"
        :page="1"
        :pages="1"
        :loading="isLoadingBrandList"
      >
        <div
          class="
            card
            row
            p-3
            d-flex
            flex-row
            justify-content-sm-end justify-content-xl-start
          "
          v-for="(item, i) in brands"
          :key="i"
        >
          <div class="col-2 col-lg-1">{{ item.id }}</div>
          <div class="col-7 col-lg-4 text-primary">
            <router-link :to="`/brands/${item.id}`">
              <div class="mx-2">{{ item.name }}</div>
            </router-link>
          </div>
          <div class="col-3 col-lg-2">
            <span
              :class="`btn btn-xs btn-block btn-${
                item.isActive === 1 ? 'primary' : 'danger'
              }`"
              >{{ item.isActive === 1 ? "On" : "Off" }}</span
            >
          </div>
          <div class="col-12 col-lg-5 mt-4 mt-lg-0">
            <router-link :to="`/brands/${item.id}`"
              ><button class="btn btn-info mx-2 btn-xs">
                Products of Brand
              </button></router-link
            >
            <button
              class="btn btn-warning mx-2 btn-xs"
              @click="
                editBrand = { ...item, index: i };
                showModal = true;
              "
            >
              <i style="font-size: 14px" class="material-icons-outlined mr-2"
                >border_color</i
              >Update
            </button>
          </div>
        </div>
      </cardTableBody>
    </div>
    <modal
      okText="Save"
      title="New Brand"
      v-model="showNewBrandModal"
      @ok="SEND_NEW_BRAND_TO_API"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newBrand.name"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Brand Name"
        />
      </div>
    </modal>
    <modal
      @ok="SEND_UPDATE_BRAND_TO_API"
      okText="Save Changes"
      title="Update Brand"
      v-model="showModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editBrand.name"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Brand Name"
        />
      </div>
      <div class="mt-4">
        <strong>Situation: </strong>
        <button
          @click="editBrand.isActive = 1"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editBrand.isActive === 0,
            'btn-primary': editBrand.isActive === 1,
          }"
        >
          Active
        </button>
        <button
          @click="editBrand.isActive = 0"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editBrand.isActive === 0,
            'btn-danger': editBrand.isActive === 0,
          }"
        >
          Archived
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import GetBrands from "./getBrands";
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
import Modal from "../ui/modal.vue";
export default {
  mixins: [GetBrands],
  data() {
    return {
      alert: {
        status: false,
        variant: "success",
        message: "",
      },
      newBrand: { name: "", isActive: 1 },
      showNewBrandModal: false,
      showModal: false,
      editBrand: {},
      fields: [
        { label: "Id", sort: false, col: 12, md: 6, lg: 1, xl: 1 },
        { label: "Name", sort: false, col: 12, md: 6, lg: 4, xl: 4 },
        { label: "Situation", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
        { label: "Actions", sort: false, col: 12, md: 6, lg: 5, xl: 5 },
      ],
    };
  },
  components: {
    Modal,
    cardTableHeader,
    cardTableBody,
  },
  methods: {
    async SEND_NEW_BRAND_TO_API(e, loading) {
      this.alert.status = false;
      if (this.newBrand.name === "") return;
      loading();
      const response = await this.$api.post("brands", {
        name: this.newBrand.name,
        isActive: 1,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Brand is updated successfully",
        };
        this.GET_INITIAL_BRAND_LIST();
        setTimeout(() => {
          this.showNewBrandModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
    async SEND_UPDATE_BRAND_TO_API(e, loading) {
      this.alert.status = false;
      if (this.editBrand.name === "") return;
      loading();
      const response = await this.$api.put(`brands/${this.editBrand.id}`, {
        name: this.editBrand.name,
        isActive: this.editBrand.isActive,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Brand saved successfully",
        };
        this.GET_INITIAL_BRAND_LIST();
        setTimeout(() => {
          this.showModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
</style>